import React, {
  Fragment,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import { TabContext } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import { debounce, isEqual } from 'lodash';
import parseUrl from 'parse-url';
import { Descendant } from 'slate';

import { useAppDispatch } from '../../redux/hooks';
import { updateCard, updateCardNotes } from '../../redux/trip/trip.slice';
import { SnackbarMessage, TripCard } from '../../redux/trip/trip.types';
import { formatPhoneNumberForLink, getOpeningHours } from '../card/card.utils';
import { usePlaceImgUrlsForCard } from '../../hooks/trip/trip.hooks';

import RichTextEditor from '../rich-text-editor/rich-text-editor.component';
import Toast from '../snackbar/snackbar.component';
import CardDetailsOptionsMenu from './card-options-menu/card-options-menu.component';
import CardImageGallery from './card-image-gallery/card-image-gallery.component';
import AddReactionButton from './card-reactions/add-reaction-button';

import {
  CardDetailsModalContainer,
  HeaderContainer,
  BackButton,
  OptionsMenuDiv,
  OptionsMenuButton,
  OpenPhotoGalleryButton,
  MainCoverImageContainer,
  CoverImage,
  StaticMapContainer,
  StaticMap,
  SubheaderContainer,
  PriceLevelText,
  BoldDollar,
  LightDollar,
  LocationTypeText,
  RatingsText,
  GoogleReviewsLinkContainer,
  GoogleReviewsAnchor,
  GoogleReviewsText,
  GoogleReviewsLaunchIcon,
  CardTitle,
  StyledTab,
  CardDetailsTabList,
  CardDetailsTabPanel,
  TextEditorContainer,
  InfoContainer,
  ContactInfoContainer,
  TimeInfoContainer,
  HoursInfoContainer,
  HoursInfoSubcontainer,
  InfoHeading,
  InfoSpan,
  StyledAnchor,
  InfoGridSpan,
} from './card-details-modal.styles';

type CardDetailsModalProps = {
  card: TripCard,
  tripId: string,
  canEdit?: boolean,
  open?: boolean,
  handleClose?: () => void,
  handleDeleteClick?: (event: MouseEvent<EventTarget>) => void,
};

function CardDetailsModal({
  card,
  tripId,
  canEdit = false,
  open = false,
  handleClose,
  handleDeleteClick,
}: CardDetailsModalProps) {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { notes } = card;
  const [isSlideVisible, setIsSlideVisible] = useState(open);
  const [isModalOpen, setIsModalOpen] = useState(open);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [tabValue, setTabValue] = useState(notes ? '1' : '2');
  const [showToolbar, setShowToolbar] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(searchParams.has('photos'));
  const [isEditCoverPhoto, setIsEditCoverPhoto] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);
  const snackbarOpen = Boolean(snackbarMessage);

  const imgUrls = card.imgUrls ?? [];

  usePlaceImgUrlsForCard(tripId, card);

  const mapId = '5f2483670fd46e10';
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    card?.location?.latitude
  },${
    card?.location?.longitude
  }&zoom=18&size=640x640&scale=2&maptype=roadmap&markers=size:lrg%7Ccolor:red%7Clabel:o%7C${
    card?.location?.latitude
  },${card?.location?.longitude}&map_id=${mapId}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`;

  const handleOptionsOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGalleryOpen = () => {
    searchParams.set('photos', 'true');
    setSearchParams(searchParams);
  };
  const handleGalleryClose = () => {
    searchParams.delete('photos');
    setSearchParams(searchParams);
    setIsEditCoverPhoto(false);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    searchParams.set('card-tab', newValue);
    setSearchParams(searchParams);
  };

  // Handle url navigation
  useEffect(() => {
    setTabValue(searchParams.get('card-tab') || tabValue);
    setGalleryOpen(searchParams.has('photos'));
  }, [searchParams, tabValue]);

  // useEffect + handleCloseModal() delays unmounting of the Modal so that the slide-in/slide-out animation can finish
  useEffect(() => {
    if (open) {
      setIsModalOpen(true);
      setIsSlideVisible(true);
    } else {
      setIsSlideVisible(false);
    }
  }, [open]);

  const handleCloseModal = () => {
    setIsSlideVisible(false);
    setTimeout(() => {
      setIsModalOpen(false);
      if (handleClose) handleClose();
    }, 100); // Delay for the duration of the slide-out animation
  };

  const makeNameEditable = (event: MouseEvent<HTMLHeadingElement>) => {
    const cardNameHeading = event.target as HTMLHeadingElement;
    cardNameHeading.setAttribute('contentEditable', 'true');
    cardNameHeading.focus();
  };

  const submitNameChanges = (
    event: FocusEvent<HTMLHeadingElement> | KeyboardEvent<HTMLHeadingElement>
  ) => {
    const cardNameHeading = event.target as HTMLHeadingElement;
    cardNameHeading.setAttribute('contentEditable', 'false');
    const text = cardNameHeading.innerText;

    // Early return if text is not changed
    if (text === (card.customName ?? card.name ?? '')) return;

    if (text.length === 0 || !text.trim()) {
      cardNameHeading.innerText = card.name ?? '';
      setSnackbarMessage({
        severity: 'error',
        text: 'Card name cannot be empty',
      });
      return;
    }

    dispatch(updateCard({ tripId, cardId: card.id, field: 'customName', value: text }));
    setSnackbarMessage({
      severity: 'success',
      text: 'Card name updated!',
    });
  };

  const submitCoverImageChange = (newCoverImage: number) => {
    dispatch(updateCard({ tripId, cardId: card.id, field: 'defaultImage', value: newCoverImage }));
    handleGalleryClose();
    setSnackbarMessage({
      severity: 'success',
      text: 'Cover photo updated!',
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLHeadingElement>) => {
    const maxCharacterCount = 200;
    const cardNameHeading = event.target as HTMLHeadingElement;
    const text = cardNameHeading.innerText;

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent creating a new line
      submitNameChanges(event);
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveNotes = useCallback(
    debounce((newNotes) => {
      if (!isEqual(newNotes, notes)) {
        dispatch(
          updateCardNotes({
            tripId,
            cardId: card.id,
            notes: newNotes,
          })
        );
      }
    }, 500),
    [dispatch, card, tripId]
  );

  const handleEditorClick = () => {
    if (canEdit) {
      setShowToolbar(true);
    }
  };
  const handleEditorBlur = () => setShowToolbar(false);

  const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'short' });

  function formatType(type: string) {
    return type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const cardType = card.types ? formatType(card.types[0]) : '';
  function getPriceLevel(priceLevel: number) {
    const totalDollars = 4;
    const dollars = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalDollars; i++) {
      dollars.push(
        i < priceLevel ? <BoldDollar key={i}>$</BoldDollar> : <LightDollar key={i}>$</LightDollar>
      );
    }
    return dollars;
  }

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Slide direction="left" in={isSlideVisible} mountOnEnter unmountOnExit>
        <CardDetailsModalContainer canEdit={canEdit}>
          <HeaderContainer>
            <BackButton startIcon={<ArrowBackIcon />} onClick={handleCloseModal} />

            {/* OPTIONS MENU FOR CARD DETAILS  */}
            {canEdit && (
              <OptionsMenuDiv onClick={handleOptionsOpen}>
                <OptionsMenuButton />
              </OptionsMenuDiv>
            )}
            <CardDetailsOptionsMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              handleGalleryOpen={handleGalleryOpen}
              handleDeleteClick={handleDeleteClick}
              setIsEditCoverPhoto={setIsEditCoverPhoto}
            />
          </HeaderContainer>

          {imgUrls.length > 0 ? (
            <MainCoverImageContainer>
              <CoverImage src={imgUrls[card.defaultImage || 0]} />
              <AddReactionButton
                card={card}
                tripId={tripId}
                canEdit={canEdit}
                showAddButton
                isCardDetails
              />
              {imgUrls.length > 1 && (
                <OpenPhotoGalleryButton onClick={handleGalleryOpen}>
                  See all photos
                </OpenPhotoGalleryButton>
              )}
            </MainCoverImageContainer>
          ) : (
            <StaticMapContainer>
              <StaticMap src={mapUrl} />
            </StaticMapContainer>
          )}

          {galleryOpen && (
            <CardImageGallery
              imgUrls={imgUrls}
              isEdit={isEditCoverPhoto}
              galleryOpen={galleryOpen}
              handleGalleryClose={handleGalleryClose}
              originalSelectedImgIndex={card.defaultImage}
              submitCoverImageChange={submitCoverImageChange}
            />
          )}
          <CardTitle
            canEdit={canEdit}
            onClick={canEdit ? makeNameEditable : undefined}
            onBlur={canEdit ? submitNameChanges : undefined}
            onKeyDown={canEdit ? handleKeyDown : undefined}
          >
            {card.customName ?? card.name}
          </CardTitle>

          <SubheaderContainer>
            {card.priceLevel && <PriceLevelText> {getPriceLevel(card.priceLevel)}</PriceLevelText>}
            {card.types && <LocationTypeText>{cardType}</LocationTypeText>}
            {card.rating && <RatingsText>{card.rating}</RatingsText>}
            {card.url && (
              <GoogleReviewsLinkContainer>
                <GoogleReviewsAnchor href={card.url} target="_blank">
                  <GoogleReviewsText>Google Reviews</GoogleReviewsText>
                  <GoogleReviewsLaunchIcon />
                </GoogleReviewsAnchor>
              </GoogleReviewsLinkContainer>
            )}
          </SubheaderContainer>

          <TabContext value={tabValue}>
            <CardDetailsTabList onChange={handleTabChange}>
              {(notes || canEdit) && <StyledTab label="Notes" value="1" />}
              <StyledTab label="Info" value="2" />
            </CardDetailsTabList>

            {(notes || canEdit) && (
              <CardDetailsTabPanel value="1" onClick={handleEditorClick} onBlur={handleEditorBlur}>
                <TextEditorContainer toolbar={showToolbar}>
                  <RichTextEditor
                    initialValue={notes}
                    onChange={(newNotes: Descendant[]) => {
                      debouncedSaveNotes(newNotes);
                    }}
                    toolbar={showToolbar}
                    placeholder={canEdit ? 'Add notes/tips for this place...' : undefined}
                    readOnly={!canEdit}
                  />
                </TextEditorContainer>
              </CardDetailsTabPanel>
            )}

            <CardDetailsTabPanel value="2">
              <InfoContainer>
                <ContactInfoContainer>
                  {card.formattedPhoneNumber && (
                    <div>
                      <InfoHeading>Phone</InfoHeading>
                      <InfoSpan>
                        {card.internationalPhoneNumber ? (
                          <StyledAnchor
                            href={`tel:${formatPhoneNumberForLink(card.internationalPhoneNumber)}`}
                          >
                            {card.internationalPhoneNumber}
                          </StyledAnchor>
                        ) : (
                          card.formattedPhoneNumber
                        )}
                      </InfoSpan>
                    </div>
                  )}
                  {card.formattedAddress && (
                    <div>
                      <InfoHeading>Address</InfoHeading>
                      <InfoSpan>
                        {card.url ? (
                          <StyledAnchor href={card.url} target="_blank" rel="noreferrer">
                            {card.formattedAddress}
                          </StyledAnchor>
                        ) : (
                          card.formattedAddress
                        )}
                      </InfoSpan>
                    </div>
                  )}
                  {card.website && (
                    <div>
                      <InfoHeading>Website</InfoHeading>
                      <InfoSpan>
                        <StyledAnchor
                          href={card.website}
                          target="_blank"
                          rel="noreferrer"
                          className="website"
                        >
                          {parseUrl(card.website).resource}
                        </StyledAnchor>
                      </InfoSpan>
                    </div>
                  )}
                </ContactInfoContainer>

                {card.openingHours && (
                  <HoursInfoContainer>
                    <InfoHeading>Hours</InfoHeading>
                    <HoursInfoSubcontainer>
                      {getOpeningHours(card.openingHours).map((openingHours, dayIndex) => {
                        const dayOfWeek = Object.keys(openingHours)[0];
                        const isToday = dayOfWeek === currentDay;
                        return (
                          <Fragment key={`${card.id}-${dayOfWeek}`}>
                            <InfoGridSpan
                              row={dayIndex + 1}
                              style={{ fontWeight: isToday ? '600' : 'normal' }}
                            >
                              {dayOfWeek}:
                            </InfoGridSpan>
                            <TimeInfoContainer row={dayIndex + 1}>
                              {openingHours[dayOfWeek].map((hours, index) => (
                                <InfoSpan
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${card.id}-hours-${dayOfWeek}-${index}`}
                                  style={{ fontWeight: isToday ? '600' : 'normal' }}
                                >
                                  {hours}
                                </InfoSpan>
                              ))}
                            </TimeInfoContainer>
                          </Fragment>
                        );
                      })}
                    </HoursInfoSubcontainer>
                  </HoursInfoContainer>
                )}
              </InfoContainer>
            </CardDetailsTabPanel>
          </TabContext>
          {snackbarOpen && snackbarMessage && <Toast message={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />}
        </CardDetailsModalContainer>
      </Slide>
    </Modal>
  );
}

CardDetailsModal.displayName = 'CardDetailsModal';
export default CardDetailsModal;
