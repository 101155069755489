import React, { useEffect, useMemo, useRef, useState } from 'react';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import { serverTimestamp } from 'firebase/firestore';
import { ReactionType } from '../../../firebase/firebase.types';
import useMobileMediaQuery from '../../../utils/media-query.utils';

import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { updateCard } from '../../../redux/trip/trip.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SnackbarMessage, TripCard } from '../../../redux/trip/trip.types';
import Toast from '../../snackbar/snackbar.component';
import { AddReactionButtonContainer, ReactionSelectorContainer, StyledButtonGroup, StyledButton } from './add-reaction-button.styles';

interface AddReactionButtonProps {
  card: TripCard,
  tripId: string,
  canEdit?: boolean,
  showAddButton?: boolean,
  isCardDetails?: boolean,
  orientation?: 'horizontal' | 'vertical',
  isLarge?: boolean,
}

function AddReactionButton({
  card,
  tripId,
  canEdit,
  showAddButton = true,
  isCardDetails = false,
  orientation = "horizontal",
  isLarge = false,
}: AddReactionButtonProps): JSX.Element {
  const reactionContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentUserId = currentUser?.uid || '';
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);
  const snackbarOpen = Boolean(snackbarMessage);

  // Calculate reaction counts and user's current reaction
  const { reactionCounts, userCurrentReaction, hasAnyReactions } = useMemo(() => {
    const counts = {
      [ReactionType.YES]: 0,
      [ReactionType.MAYBE]: 0,
      [ReactionType.NO]: 0,
    };

    // Count all reactions
    const entries = Object.entries(card?.reactions || {});
    entries.forEach(([, reaction]) => {
      counts[reaction.type] += 1
    });

    // Get user's current reaction
    const userReaction = card?.reactions?.[currentUserId];
    const hasReactions = entries.length > 0;

    return {
      reactionCounts: counts,
      userCurrentReaction: userReaction,
      hasAnyReactions: hasReactions
    };
  }, [card?.reactions, currentUserId]);

  const [showSelector, setShowSelector] = useState(hasAnyReactions);

  const onReactionSelect = (reaction: ReactionType | null): void => {
    if (!canEdit) {
      setSnackbarMessage({
        severity: 'warning',
        text: 'You must be a collaborator of this trip in order to vote.',
      });
      return;
    }

    if (currentUser?.uid) {
      const reactions = {...(card.reactions || {})};

      if (reaction === null) {
        // Remove the user's reaction if it exists
        if (reactions[currentUser.uid]) {
          delete reactions[currentUser.uid];
        }
      } else {
        // Add or update the user's reaction
        reactions[currentUser.uid] = {
          type: reaction,
          timestamp: serverTimestamp(),
          email: currentUser.email
        };
      };

      dispatch(updateCard(
        {
          tripId,
          cardId: card.id,
          field: 'reactions',
          value: reactions
        }
      ));
    }
  }

  // Close the reaction selector when clicking outside of it for cards
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        !isCardDetails &&
        reactionContainerRef.current &&
        !reactionContainerRef.current.contains(e.target as Node)
      ) {
        setShowSelector(false);
      }
    };

    if (showSelector) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSelector, isCardDetails]);

  const handleClick = (e: React.MouseEvent) => {
    // Stop event propagation to prevent CardContainer click
    e.stopPropagation();
    setShowSelector(!showSelector);
  };

  const handleReactionSelect = (reaction: ReactionType, e: React.MouseEvent): void => {
    // Stop event propagation to prevent CardContainer click
    e.stopPropagation();

    if (userCurrentReaction?.type === reaction) {
      onReactionSelect(null); // for removing the selection
    } else {
      onReactionSelect(reaction);
    }

    if (!isCardDetails) {
      setShowSelector(false);
    }
  };

  const isSelected = (reaction: ReactionType) => userCurrentReaction?.type === reaction;

  return (
    <ReactionSelectorContainer
      ref={reactionContainerRef}
      onClick={(e) => e.stopPropagation()}
      isMobile={isMobile}
      isCardDetails={isCardDetails}
      isLarge={isLarge}
    >
      {showSelector && (
        <StyledButtonGroup
          variant="text"
          disableElevation
          size="small"
          isCardDetails={isCardDetails}
          orientation={orientation}
        >
          <StyledButton
            onClick={(e) => handleReactionSelect(ReactionType.YES, e)}
            isSelected={isSelected(ReactionType.YES)}
            isCardDetails={isCardDetails}
          >
            👍 {isCardDetails && reactionCounts[ReactionType.YES]}
          </StyledButton>
          <StyledButton
            onClick={(e) => handleReactionSelect(ReactionType.MAYBE, e)}
            isSelected={isSelected(ReactionType.MAYBE)}
            isCardDetails={isCardDetails}
          >
            🤔 {isCardDetails && reactionCounts[ReactionType.MAYBE]}
          </StyledButton>
          <StyledButton
            onClick={(e) => handleReactionSelect(ReactionType.NO, e)}
            isSelected={isSelected(ReactionType.NO)}
            isCardDetails={isCardDetails}
          >
            👎 {isCardDetails && reactionCounts[ReactionType.NO]}
          </StyledButton>
        </StyledButtonGroup>
      )}
      {showAddButton && (
        <AddReactionButtonContainer onClick={handleClick} isCardDetails={isCardDetails}>
          <AddReactionOutlinedIcon />
        </AddReactionButtonContainer>
      )}
      {snackbarOpen && snackbarMessage && (
        <Toast message={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />
      )}
    </ReactionSelectorContainer>
  );
}

AddReactionButton.displayName = 'AddReactionButton';
export default AddReactionButton;