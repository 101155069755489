import styled, { css } from 'styled-components';
import { TabList, TabPanel } from '@mui/lab';
import { Button, Tab } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LaunchIcon from '@mui/icons-material/Launch';

export const CardDetailsModalContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 22px 0px;
  background-color: #fafafa;
  overflow-y: auto;
`;

export const CardDetailsButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const BackButton = styled(Button)`
  min-width: 0;
`;

export const OptionsMenuDiv = styled.div``;

export const OptionsMenuButton = styled(MoreHorizIcon)``;

export const ModalImagesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
`;

export const CoverImage = styled.img`
  width: 92%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const OpenGalleryButton = styled.button`
  position: absolute;
  color: white;
  font-size: 12px;
  background-color: rgba(41, 41, 41, 0.75);
  border: none;
  border-radius: 10px;
  bottom: 1em;
  right: 1.5em;
  padding: 8px 12px;
  z-index: 1;
`;

export const CardTitle = styled.h1`
  font-weight: bold;
  font-size: 25px;
  margin: 20px 17px 12px 17px;
  :focus {
    border-bottom: 0.15rem solid #17a8b2;
    outline: none;
  }
`;

export const SubheaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-left: 18px;
  margin-bottom: 14px;
  font-size: 14px;
`;

export const PriceLevelText = styled.span`
  margin: 0;
  padding: 0;
`;

export const BoldDollar = styled.span`
  font-weight: bold;
  color: black;
`;

export const LightDollar = styled.span`
  opacity: 0.5;
  font-weight: bold;
  color: gray;
`;

export const LocationTypeText = styled.span`
  text-transform: capitalize;
  margin: 0;
  padding: 0;
`;

export const RatingsText = styled.span`
  margin: 0;
  padding: 0;
  margin-right: -6px;
`;

export const GoogleReviewsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const GoogleReviewsAnchor = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const GoogleReviewsText = styled.span`
  text-decoration: underline;
  margin: 0;
  padding: 0;
  margin-right: 4px;
`;

export const GoogleReviewsLaunchIcon = styled(LaunchIcon)`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const CardDetailsTabList = styled(TabList)`
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  margin: 0px 17px;

  & .Mui-selected {
    color: black;
  }

  & .MuiTabs-indicator {
    background-color: black;
  }
`;

export const StyledTab = styled(Tab)`
  min-height: 44px;
`;

type CardDetailsModalTabPanelProps = {
  value: string,
};

export const CardDetailsTabPanel = styled(TabPanel)<CardDetailsModalTabPanelProps>`
  padding: ${(props) => (props.value === '1' ? '8px 14px' : '8px 24px')};
`;

type CardDetailsModalEditorContainerProps = {
  toolbar: boolean,
};

export const TextEditorContainer = styled.div<CardDetailsModalEditorContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  height: 400px;

  ${(props) => {
    if (props.toolbar) {
      return css`
        div:nth-child(1) {
          flex-shrink: 0; /* Ensure the toolbar does not shrink */
          overflow-x: auto; /* Make the toolbar scrollable on the x-axis */
          overflow-y: hidden; /* Prevent vertical scrolling for the toolbar */
          white-space: nowrap; /* Prevent wrapping of toolbar items */

          /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
          &::-webkit-scrollbar {
            display: none;
          }
        }
        div:nth-child(2) {
          flex-grow: 1; /* Allow the content area to grow */
          overflow-y: auto; /* Make the content area scrollable on the y-axis */
          overflow-x: hidden; /* Prevent horizontal scrolling for the content area */
          font-size: 0.875rem;
          line-height: 1.25rem;
          letter-spacing: 0.0225rem;
          padding: 6px;
        }
      `;
    }
    return css`
      div:first-child {
        height: 350px;
        overflow-y: auto; /* Make the content area scrollable on the y-axis */
        overflow-x: hidden; /* Prevent horizontal scrolling for the content area */
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.0225rem;
      }
    `;
  }}
`;

export const InfoContainer = styled.div`
  place-items: start;
  place-content: start;
`;

export const ContactInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const HoursInfoContainer = styled.div``;

export const HoursInfoSubcontainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
`;

type CardDetailsModalInfoTimeContainerProps = {
  row: number,
};

export const TimeInfoContainer = styled.div<CardDetailsModalInfoTimeContainerProps>`
  display: flex;
  flex-flow: column nowrap;
`;

export const InfoHeading = styled.h4`
  font-size: 0.975rem;
  margin-bottom: 3px;
`;

export const InfoSpan = styled.span`
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 3px;
`;

type CardDetailsModalInfoGridSpanProps = {
  row: number,
};

export const InfoGridSpan = styled(InfoSpan) <CardDetailsModalInfoGridSpanProps>``;

export const ModalOptionsMenu = styled.div`
  align-self: end;
  display: flex;
  flex-flow: column wrap-reverse;
`;

export const ModalOptionsDeleteButton = styled(Button)`
  max-width: 120px;
  color: black;
`;

export const StyledAnchor = styled.a`
  color: blue;
`;

export const StaticMapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StaticMap = styled.img`
  margin-top: 10px;
  width: 340px;
  height: 200px;
  object-fit: cover;
`;
