import { Button, ButtonGroup } from '@mui/material';
import styled from 'styled-components';

type ReactionSelectorContainerProps = {
  isMobile: boolean,
  isCardDetails: boolean,
  isLarge: boolean,
}
export const ReactionSelectorContainer = styled.div<ReactionSelectorContainerProps>`
  ${(props) => props.isCardDetails ? `
    position: absolute;
    top: 10px;
    right: ${props.isMobile ? '24px' : '12px'};
  ` : `
    display: block;
    position: absolute;
    bottom: ${props.isLarge ? '100px' : '0px'};
    right: ${props.isLarge ? '10px' : '0px'};
  `}
`;

type StyledButtonGroupProps = {
  isCardDetails: boolean,
}
export const StyledButtonGroup = styled(ButtonGroup)<StyledButtonGroupProps>`
  position: absolute;
  padding: 3px;
  border-radius: 50px;
  z-index: 99;

  ${(props) => props.isCardDetails ? `
    right: 40px;
    background-color: rgba(41, 41, 41, 0.75);
    border: 1px white solid;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  `
  : `
    top: 35px;
    background-color: rgba(255, 255, 255);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  `
  }

  /* Remove connected appearance */
  .MuiButtonGroup-grouped {
    margin: 0 !important;
    border: none !important;
    border-radius: 50px !important;
  }
`;

type StyledButtonProps = {
  isSelected: boolean,
  isCardDetails: boolean,
}
export const StyledButton = styled(Button)<StyledButtonProps>`
  min-height: 25px;
  background-color: transparent;
  font-size: 14px;
  color: white;

  ${(props) => props.isCardDetails ? `
    padding: 0;
    min-width: 45px;
  ` : `
    padding: 0 4px;
    min-width: 20px !important;
  `}

  &:hover {
    background-color: rgba(255, 205, 41, 0.75);
    color: black;
  }

  ${(props) => props.isSelected && `
    background-color: rgba(255, 205, 41, 0.75);
    color: black;
  `}
`;

type AddReactionButtonContainerProps = {
  isCardDetails: boolean,
}
export const AddReactionButtonContainer = styled.button<AddReactionButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;

  ${(props) => props.isCardDetails ? `
    width: 35px;
    height: 35px;
    background-color: rgba(41, 41, 41, 0.75);
    color: white;
    border: 1px white solid;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: rgba(41, 41, 41)
    }
  `
  : `
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255);
    color: gray;
    border: none;

    &:hover {
      background-color: rgba(255, 255, 255);
    }
  `
  }
`;
